@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    scroll-behavior: smooth; 
}

::-webkit-scrollbar {
    display: none;
}

@layer base {
    body {
        @apply bg-[#EFF2F6];
        @apply font-sans;
    }
}

/* App.css */
.app-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.franchise-selection {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity 0.8s ease-in-out;
}

.franchise-selection.fade-out {
    opacity: 0;
}

.login-page {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

.login-page.ease-in {
    opacity: 1;
    visibility: visible;
}

.login-page.from-left.ease-in {
    transform: translateX(0%);
}

.login-page.from-right.ease-in {
    transform: translateX(0%);
}

/* Initial positions for animations */
.login-page.from-left {
    transform: translateX(-100%);
}

.login-page.from-right {
    transform: translateX(100%);
}


.del-btn {
	@apply px-2 py-1.5 bg-red-500 rounded-md border border-red-500 hover:border-red-500 text-white hover:text-red-500 hover:bg-white shadow-md transition-all duration-200 ease-in-out;
}
.delete-req-btn {
	@apply py-1.5 px-4 text-base font-medium text-red-500 border border-red-500 bg-white rounded-md shadow-md hover:bg-red-500 hover:text-white transition-colors duration-200 ease-in-out;
}
.view-btn {
	@apply px-2 py-1.5 bg-[#4ec784] rounded-md border border-[#4ec784] hover:border-[#4ec784] text-white hover:text-[#4ec784] hover:bg-white shadow-md transition-all duration-200 ease-in-out;
}
.edit-btn {
	@apply px-2 py-1.5 bg-[#51cbce] rounded-md border border-[#51cbce] hover:border-[#51cbce] text-white hover:text-[#51cbce] hover:bg-white shadow-md transition-all duration-200 ease-in-out;
}
.generate-btn {
	@apply px-2 py-1.5 bg-[#4361ee] rounded-md border border-[#4361ee] hover:border-[#4361ee] text-white hover:text-[#4361ee] hover:bg-white shadow-md transition-all duration-200 ease-in-out;
}
.action-btn {
	@apply px-2 py-1.5 bg-[#ff6d00] rounded-md border border-[#ff6d00] hover:border-[#ff6d00] text-white hover:text-[#ff6d00] hover:bg-white shadow-md transition-all duration-200 ease-in-out;
}
.upload-btn {
	@apply px-2 py-1.5 bg-[#38b000] rounded-md border border-[#38b000] hover:border-[#38b000] text-white hover:text-[#38b000] hover:bg-white shadow-md transition-all duration-200 ease-in-out;
}
.select-btn {
	@apply px-2 py-1.5 bg-[#613dc1] rounded-md border border-[#613dc1] hover:border-[#613dc1] text-white hover:text-[#613dc1] hover:bg-white shadow-md transition-all duration-200 ease-in-out;
}
.clear-btn {
	@apply bg-slate-500 hover:bg-white border border-[#c4c7c9] hover:border-[#353535] px-4 py-[8.5px] text-white hover:text-black font-Poppins rounded-md text-sm transition-all duration-300 ease-in-out;
}
.search-btn {
	@apply bg-[#4ece76] hover:bg-white border border-[#4ece76] hover:border-[#4ece76] px-4 py-[8.5px] text-white hover:text-[#4ece76] font-Poppins rounded-md text-sm transition-all duration-200 ease-in-out;
}
.add-more-btn {
	@apply py-1.5 px-4 text-base font-medium text-[#353535] border border-[#353535] bg-white rounded-md shadow-md hover:bg-[#353535] hover:text-white transition-colors duration-200 ease-in-out mt-2;
}

.custom-dropdown {
    display: flex;
    flex-direction: row;
}

.custom-dropdown .MuiMenuItem-root {
    display: flex;
    align-items: center;
}

.react-datepicker__input-container input::placeholder {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    color: gray;
    border: black;
  }

  .edit-btn-primary {
    @apply px-2 py-1.5 bg-[#007BFF] rounded-md border border-[#007BFF] hover:border-[#007BFF] text-white hover:text-[#007BFF] hover:bg-white shadow-md transition-all duration-200 ease-in-out;
  }
  
  .edit-btn-success {
    @apply px-2 py-1.5 bg-[#28A745] rounded-md border border-[#28A745] hover:border-[#28A745] text-white hover:text-[#28A745] hover:bg-white shadow-md transition-all duration-200 ease-in-out;
  }

  .edit-btn-danger {
    @apply px-2 py-1.5 bg-[#DC3545] rounded-md border border-[#DC3545] hover:border-[#DC3545] text-white hover:text-[#DC3545] hover:bg-white shadow-md transition-all duration-200 ease-in-out;
  }
  .edit-btn-warning {
    @apply px-2 py-1.5 bg-[#FFC107] rounded-md border border-[#FFC107] hover:border-[#FFC107] text-white hover:text-[#FFC107] hover:bg-white shadow-md transition-all duration-200 ease-in-out;
  }
  
  

  .clear-btn-info {
    @apply bg-teal-500 hover:bg-teal-100 border border-teal-600 hover:border-teal-400 px-4 py-[8.5px] text-white hover:text-teal-600 font-Poppins rounded-md text-sm transition-all duration-300 ease-in-out;
  }
  .clear-btn-light {
    @apply bg-gray-200 hover:bg-gray-300 border border-gray-400 hover:border-gray-500 px-4 py-[8.5px] text-gray-800 hover:text-gray-900 font-Poppins rounded-md text-sm transition-all duration-300 ease-in-out;
  }
  .clear-btn-danger {
    @apply bg-red-600 hover:bg-red-200 border border-red-700 hover:border-red-600 px-4 py-[8.5px] text-white hover:text-red-600 font-Poppins rounded-md text-sm transition-all duration-300 ease-in-out;
  }
  .clear-btn-success {
    @apply bg-green-600 hover:bg-green-200 border border-green-700 hover:border-green-600 px-4 py-[8.5px] text-white hover:text-green-600 font-Poppins rounded-md text-sm transition-all duration-300 ease-in-out;
  }
  